export const REDIRECT_URI_KEY = "@optimizely/home:okta-redirect-uri";

export const FEATURE_FLAGS = {
    NEW_UI: "enable_new_ui"
};

export const INVALID_NAVIGATION_URL = "Invalid NavigationUrl. Contact your admin for further assistance.";

export const HOME_DASHBOARD = "Home Dashboard";

export const COMMON_PLATFORM = "Common Platform";

export const NON_ORG_ID_PATHS = [
    "accept-invitation",
    "logout",
    "callback"
];

export const GLOBAL_BAR_ORG_CHANGE_EVENT_NAME = "@opti-product-switcher:set";
export const SEGMENT_PRODUCT_NAVIGATION_EVENT_NAME = "@home:product-selected";
export const SEGMENT_SUPPORT_NAVIGATION_EVENT_NAME = "@home:help-and-resource-link-navigation";
