import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useOrganization } from "../../hooks/useOrganization";
import { useOktaCredentials } from "../../hooks/useOktaCredentials";
import { emitSegmentGroupEvent, emitSegmentIdentifyEvent, emitSegmentPageEvent, emitSegmentTrackEvent } from "../../services/segment";
import { NON_ORG_ID_PATHS, SEGMENT_PRODUCT_NAVIGATION_EVENT_NAME, SEGMENT_SUPPORT_NAVIGATION_EVENT_NAME } from "../../utils/constants";

export const SegmentTracking = () => {
    const location = useLocation();
    const { accessToken, email, name } = useOktaCredentials();

    let [, organizationId = ""] = location.pathname.split("/");
    if (NON_ORG_ID_PATHS.includes(organizationId)) organizationId = "";

    const { organization } = useOrganization({ accessToken, organizationId });

    const prevEmailRef = useRef<string | null>(null);
    const prevOrgIdRef = useRef<string | null>(null);
    const prevPageRef = useRef<string | null>(null);

    const handleNavigationEvent = useCallback(
        (eventName: string) => (e: Event): void => {
            const data = (e as CustomEvent).detail;

            if (organization?.CrmId) {
                emitSegmentTrackEvent({
                    eventName,
                    customerId: organization?.CrmId,
                    email,
                    data,
                });
            }
        },
        [email, organization?.CrmId]
    );

    useEffect(() => {
        if (organization?.CrmId && email !== prevEmailRef.current) {
            emitSegmentIdentifyEvent({ organization, email, name });
            prevEmailRef.current = email;
        }
    }, [email, organization, name]);

    useEffect(() => {
        if (organization?.CrmId && organization.Id !== prevOrgIdRef.current) {
            emitSegmentGroupEvent(organization);
            prevOrgIdRef.current = organization.Id;
        }
    }, [organization]);

    useEffect(() => {
        const viewName = (location.pathname.endsWith("/")
            ? location.pathname.slice(0, -1)
            : location.pathname).split("/").pop();

        if (organization?.CrmId && viewName !== prevPageRef.current) {
            emitSegmentPageEvent({ organization, page: viewName ?? "" });
            prevPageRef.current = viewName ?? null;
        }
    }, [location.pathname, organization]);

    useEffect(() => {
        const handleProductNavigation = handleNavigationEvent("Product Navigation");
        window.addEventListener(SEGMENT_PRODUCT_NAVIGATION_EVENT_NAME, handleProductNavigation);

        const handleSupportNavigation = handleNavigationEvent("Support Navigation");
        window.addEventListener(SEGMENT_SUPPORT_NAVIGATION_EVENT_NAME, handleSupportNavigation);

        return () => {
            window.removeEventListener(SEGMENT_PRODUCT_NAVIGATION_EVENT_NAME, handleProductNavigation);
            window.removeEventListener(SEGMENT_SUPPORT_NAVIGATION_EVENT_NAME, handleSupportNavigation);
        };
    }, [handleNavigationEvent]);

    return null;
};