import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation, withRouter } from "react-router-dom";
import { OktaAuth } from "@okta/okta-auth-js";
import { LoginCallback, Security, SecureRoute } from "@okta/okta-react";
import { config } from "./config";
import { Redirector } from "./pages/Redirector";
import CommonHeader from "./components/CommonHeader/CommonHeader";
import { DashboardPage } from "./pages/DashboardPage";
import { InvitationPage } from "./pages/InvitationPage";
import { InstancesPage } from "./pages/InstancesPage";
import { Logout } from "./pages/Logout";
import datadogRum from "./services/datadog-rum";
import { FeatureFlagWrapper } from "./components/FeatureFlagWrapper/FeatureFlagWrapper";
import { useQuery } from "./hooks/useQuery";
import OktaOptionsBuilder from "./utils/oktaOptionsBuilder";
import { SegmentTracking } from "./components/SegmentTracking/SegmentTracking";

const oktaAuth = new OktaAuth({
    issuer: config.OKTA_ISSUER as string,
    clientId: config.CLIENT_ID,
    redirectUri: window.location.origin + "/callback",
    scopes: config.SCOPE,
    // overwrite Okta's SDK default behavior of writing to localStorage
    storageManager: {
        token: {
            storageTypes: ["sessionStorage"]
        },
        cache: {
            storageTypes: ["sessionStorage"]
        },
        transaction: {
            storageTypes: ["sessionStorage"]
        }
    }
});

function App() {
    const location = useLocation();
    const query = useQuery();


    const restoreOriginalUri = async (_oktaAuth: any, originalUri?: string) => {
        const uri = originalUri || window.location.origin;
        window.location.replace(uri.endsWith("/") ? uri : `${uri}/`);
        // history.replace(toRelativeUrl(originalUri || "/", window.location.origin));
    };

    function onAuthRequired(_oktaAuth: OktaAuth) {
        const helper = new OktaOptionsBuilder();
        const options = helper.buildOktaOptions(query);
        oktaAuth.signInWithRedirect(options);
    }

    useEffect(() => {
        const viewName = (location.pathname.endsWith("/") ?
            location.pathname.slice(0, -1) :
            location.pathname).split("/").pop();
        datadogRum.startView({
            name: viewName || "dashboard"
        });
    }, [location.pathname]);

    return (
        <Router>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired} >
                <FeatureFlagWrapper>
                    <SegmentTracking />
                    <Switch>
                        <Route path="/accept-invitation" render={() => (
                            <>
                                <CommonHeader />
                                <InvitationPage />
                            </>
                        )} />
                        <Route path="/logout" render={() => (
                            <>
                                <CommonHeader />
                                <Logout />
                            </>
                        )} />
                        <Route path="/callback" component={LoginCallback} />
                        <SecureRoute path="/" exact render={() => (
                            <>
                                <CommonHeader navigateOnOrgChange />
                                <Redirector />
                            </>
                        )} />
                        <SecureRoute path="/:organizationId">
                            <>
                                <CommonHeader />
                                <Switch>
                                    <Redirect path="/dashboard" exact to="/" />
                                    <SecureRoute path="/:organizationId/dashboard" exact component={DashboardPage} />
                                    <SecureRoute path="/:organizationId/:productName/instances" component={InstancesPage} />
                                    <Route path="*" render={() => (
                                        <>
                                            <opti-error-404></opti-error-404>
                                        </>
                                    )} />
                                </Switch>
                            </>
                        </SecureRoute>
                        <Route path="*" render={() => (
                            <>
                                <CommonHeader navigateOnOrgChange />
                                <opti-error-404></opti-error-404>
                            </>
                        )} />
                    </Switch>
                </FeatureFlagWrapper>
            </Security>
        </Router>
    );
}

const AppWithRouterAccess = withRouter(App);

class RouterApp extends Component {
    render() {
        return (
            <Router>
                <AppWithRouterAccess />
            </Router>
        );
    }
}

export default RouterApp;