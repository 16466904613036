import { Organization } from '@/types/Organization.type';
import { useState, useEffect } from 'react';

type OrganizationProps = {
    accessToken: string | undefined;
    organizationId: string;
};

export const useOrganization = ({ accessToken, organizationId }: OrganizationProps) => {
    const [organization, setOrganization] = useState<Organization | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_IDX_SERVICE_URL}/api/organizations/${organizationId}`,
                    {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'application/json'
                        }
                    });

                if (!response.ok) throw new Error(`Error: ${response.statusText}`);

                const organization = await response.json();

                setOrganization(organization);
            } catch (err) {
                console.error('Failed to fetch instance permissions', err);
                setError('Failed to fetch instance permissions');
            } finally {
                setLoading(false);
            }
        };

        accessToken && organizationId && fetchData();
    }, [accessToken, organizationId]);

    return { organization, loading, error };
};
