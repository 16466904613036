import { useInstancePermissions } from "../hooks/useInstancePermissions";
import { useParams } from "react-router-dom";
import { getInstancesByProducts } from "../utils/instance-helpers";
import { Grid, GridContainer, Typography } from "@optimizely/axiom";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { useDecision } from "@optimizely/react-sdk";
import { FEATURE_FLAGS } from "../utils/constants";
import { DashboardLayout } from "../components/DashboardLayout/DashboardLayout";
import { ProductGrid } from "../components/ProductGrid/ProductGrid";
import { ProductAccessDashboard } from "../components/ProductAccessDashboard/ProductAccessDashboard";
import { EmptyDashboard } from "../components/EmptyDashboard/EmptyDashboard";
import { useOktaCredentials } from "../hooks/useOktaCredentials";

export const DashboardPage = () => {
    const { organizationId } = useParams<{ organizationId: string }>();
    const [decision] = useDecision(FEATURE_FLAGS.NEW_UI, undefined, {
        overrideAttributes: {
            organizationId
        }
    });
    const { enabled: showNewUI } = decision;
    const { accessToken, email, name } = useOktaCredentials();

    const { instancePermissions, loading, error } = useInstancePermissions({ email, accessToken });

    if (loading) {
        return <LoadingIndicator />
    }

    if (error) {
        return <div>An error occurred: {error}</div>
    }

    const organizationInstances = instancePermissions?.filter((ip: any) => {
        return ip.OrganizationId === organizationId;
    });
    const instancesByProducts = getInstancesByProducts({ instances: organizationInstances });
    const message = organizationInstances?.length ? 'Dashboard shows all the products and instances you have access to. Select a product instance to get started.' : 'You have not been granted access to any entitlements in the org you navigated to. Please contact your account manager for assistance.';
    const organizationName = organizationInstances?.length ? organizationInstances[0].OrganizationName : "";

    const [userFirstName] = name?.split(" ") || [];
    const productNames = Object.keys(instancesByProducts || {});
    const accessDashboard = !productNames.length ? <EmptyDashboard name={userFirstName} /> : <ProductAccessDashboard name={userFirstName} email={email} products={instancesByProducts} />;

    return (
        <>
            {showNewUI
                ? <DashboardLayout>{accessDashboard}</DashboardLayout>
                : (
                    <GridContainer>
                        <Grid className="flex flex-column">
                            <Typography type="header1">
                                Welcome Back {name}!
                            </Typography>
                            <Typography type="body">
                                {message}
                            </Typography>
                            <Typography type="header4" className="push--bottom">
                                {organizationName}
                            </Typography>
                        </Grid>
                        <Grid>
                            <ProductGrid email={email} instancesByProducts={instancesByProducts} />
                        </Grid>
                    </GridContainer>
                )
            }
        </>
    );
}

DashboardPage.displayName = 'DashboardPage';