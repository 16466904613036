import { Link, useParams } from "react-router-dom";
import { useInstancePermissions } from "../hooks/useInstancePermissions";
import { getInstancesForProduct } from "../utils/instance-helpers";
import { ProductInstancesTable } from "../components/ProductInstancesTable/ProductInstancesTable";
import { LoadingIndicator } from "../components/LoadingIndicator/LoadingIndicator";
import { useOktaCredentials } from "../hooks/useOktaCredentials";

export const InstancesPage = () => {
    const { organizationId, productName = "" } = useParams<{ organizationId: string, productName: string }>();
    const { accessToken, email } = useOktaCredentials();

    const product = decodeURI(productName || "");

    const { instancePermissions, loading, error } = useInstancePermissions({ email, accessToken });

    if (loading) {
        return <LoadingIndicator />
    }

    if (error) {
        return <div>An error occurred: {error}</div>
    }

    const productInstances = getInstancesForProduct({ instances: instancePermissions, organizationId, productName: product });

    return (
        <>
            <div className="push-double--top push-quad--left" >
                <Link className="push--top" to={`/${organizationId}/dashboard`} style={{ color: 'var(--axiom-link-default-text-color)' }}>
                    <svg height="14px" width="14px" className="svg-inline--fa fa-arrow-left" data-prefix="fal" data-icon="arrow-left" role="img"
                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg="">
                        <path fill="currentColor"
                            d="M4.7 244.7c-6.2 6.2-6.2 16.4 0 22.6l176 176c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6L54.6 272 432 272c8.8 0 16-7.2 16-16s-7.2-16-16-16L54.6 240 203.3 91.3c6.2-6.2 6.2-16.4 0-22.6s-16.4-6.2-22.6 0l-176 176z"></path>
                    </svg>
                    <span style={{ marginLeft: '4px' }}>Dashboard</span>
                </Link>
            </div>

            <ProductInstancesTable email={email} instances={productInstances} product={productName} />
        </>
    )
};

InstancesPage.displayName = 'InstancesPage';